ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 6px;
}

ul::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

ul::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #888;
}

.ch-input {
    box-sizing: border-box;
}

.ch-header {
    margin-bottom: 0em !important;
}

.chat-message {
    margin-block-end: 1em;
}

.chat-message a {
    color: inherit;
}

.chat-message blockquote {
    border-left: 0.5rem solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
}

.chat-message blockquote p {
    margin: 0;
}

.chat-message p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.chat-message ul {
    list-style-type: disc;
}

.chat-edit-note {
    font-size: 80%;
    text-align: right;
    font-style: italic;
}
